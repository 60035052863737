import { React, useState, useEffect, useReducer } from 'react';
import { useAuth } from '../../Context';

import { Avatar, Popover, Empty, Row, Col, Select, Typography, Tooltip, Checkbox, Spin, Space, Card, DatePicker, Modal, message } from 'antd';
import { Content } from 'antd/es/layout/layout';

import Chart from 'react-apexcharts';
import useResizeObserver from 'use-resize-observer';

import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { DAILY_CPS_LIST, DAILY_CPS_LIST_SUCCESS, DAILY_CPS_LIST_ERROR }  from './constants';
import dailyCPSReducer from './reducer';

import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import { InfoOutlined, LineChartOutlined } from '@ant-design/icons';
import { enddatetimeCoverter, startdatetimeCoverter } from '../../datetime-converter';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;
const dateFormat = 'YYYY-MM-DD';

export default function DailyCPSReport() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const [regionId, setRegionId] = useState(null);
	const [tlpId, setTLPId] = useState(null);
	const [filterDate, setFilterDate] = useState(new Date());

	const { ref, width } = useResizeObserver();

	const [filterVDC1, setFilterVDC1] = useState(false);
	const [filterVAC1, setFilterVAC1] = useState(false);
	const [filterVDC2, setFilterVDC2] = useState(false);
	const [filterVAC2, setFilterVAC2] = useState(false);
	const [filterBattery, setFilterBattery] = useState(false);
	const [filterCurrent, setFilterCurrent] = useState(false);
	const [filterTemp, setFilterTemp] = useState(false);
	const [filterVDC1Min, setFilterVDC1Min] = useState(false);
	const [filterVDC1Max, setFilterVDC1Max] = useState(false);
	const [filterVAC1Min, setFilterVAC1Min] = useState(false);
	const [filterVAC1Max, setFilterVAC1Max] = useState(false);
	const [filterVDC2Min, setFilterVDC2Min] = useState(false);
	const [filterVDC2Max, setFilterVDC2Max] = useState(false);
	const [filterVAC2Min, setFilterVAC2Min] = useState(false);
	const [filterVAC2Max, setFilterVAC2Max] = useState(false);
	const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
	
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	
	const [series, setSeries] = useState([]);
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

	const initalState = { cps: [], loading: false, error: false };
	const [data, dispatch] = useReducer(dailyCPSReducer, initalState);
  
	let regions = [{ value: null, label:  langdata && langdata.ALL ? langdata.ALL : 'ALL' }];
	let tlps = [];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);

	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));
	const navigate = useNavigate();

	let timestamps = [];
	let vac1s = [];
	let vdc2NoRefs = [];
	let vdc2Refs = [];
	let vdc1NoRefs = [];
	let vdc1Refs = [];
	let vac2s = [];
	let vbats = [];
	let temps = [];
	let currents = [];
	let vac1Mins = [];
	let vac1Maxs = [];
	let vdc1Mins = [];
	let vdc1Maxs = [];
	let vac2Mins = [];
	let vac2Maxs = [];
	let vdc2Mins = [];
	let vdc2Maxs = [];
	const hours = [];
	const d = new Date();
	const [currentHour, setCurrentHour] = useState(d && d.getHours() ? d.getHours() : null);

	const from_datetimeobj=[];

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);

	const companyType = localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toLowerCase();
	//const companyType =  'iocl';
	
	
	if(filterDate && currentHour) {
		filterDate.setHours(currentHour,0,0);
		let startdatetime=startdatetimeCoverter(filterDate);
		from_datetimeobj.push({startdatetime});

	}

	const start_date =from_datetimeobj && from_datetimeobj[0] ? from_datetimeobj[0].startdatetime.utcdate : moment.utc(filterDate.setHours(0,0,0,0)).format('YYYY-MM-DD');
	const start_time =from_datetimeobj && from_datetimeobj[0] ? `${from_datetimeobj[0].startdatetime.utctime}.000000` : `${moment.utc(filterDate.setHours(0,0,0,0)).format('HH:mm:ss')}.000000`;

	const to_datetimeobj=[];

	if(filterDate && currentHour ){
		filterDate.setHours(currentHour,59,59);
		// filterDate.setHours(parseFloat(filterEndTime));
		let enddatetime=enddatetimeCoverter(filterDate);
		to_datetimeobj.push({enddatetime});

	}

	const end_time =to_datetimeobj && to_datetimeobj[0] ? `${to_datetimeobj[0].enddatetime.utctime}.000000` : `${moment.utc(filterDate.setHours(23,59,59,999)).format('HH:mm:ss')}.000000`;
	const end_date =to_datetimeobj && to_datetimeobj[0] ? to_datetimeobj[0].enddatetime.utcdate : moment.utc(filterDate.setHours(23,59,59,999)).format('YYYY-MM-DD');

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.DailyCPSContent1 ? langdata.DailyCPSContent1 : 'This module is used to plot the values received for a specific Smart TLP device within a particular day and time interval (1 hour).'}</p>
			<br/>
		</Card>
	);

	let options = {
		id: 'dailyCchart',
		dataLabels: {
			enabled: false
		},
		title: {
			text: langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport : 'DAILY CPS CHART',
			align: 'left'
		},
		chart: {
			toolbar: {
				show: false
			}
		},
		legend: {
			show: true,
			position: 'top',
			horizontalAlign: 'left',
			onItemClick: {
				toggleDataSeries: false
			},
			onItemHover: {
				highlightDataSeries: false
			}
		},
		xaxis: {
			title: {
				text: langdata && langdata.TIMESTAMP ? langdata.TIMESTAMP :  'TIMESTAMP'
			},
			categories: timestamps,
			labels: {
				show: true,
				showDuplicates: true,
				rotate: -90,
				formatter: function (val) {
					let _date = dayjs(val, 'DD/MM/YYYY hh:mm/ss A').format('hh:mm:ss A');
					
					return _date;
				}
			}
		},
		markers: {
			size: 5,
			shape: 'square'
		},
		stroke: {
			curve: 'smooth',
			width: 2
		}
	};

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	const handleChangeRegion = (e, options) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
		}
		setTLPId([null]);
	};

	data && data.tlps && data.tlps.map((tlp) => {
		return tlps.push({ value: tlp.deviceId, label: tlp.pipelineChainage.toString() });
	});

	const handleChangeTLP = (e) => {
		setTLPId(e);
	};

	const handleDate  = (dateString) => {
		setFilterDate(new Date(dateString && dateString.$d));
		setDate(dayjs(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD'));
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	useEffect(() => {
		if(featuresModule.dailyCPSEnabled == false) {
			navigate('/dashboard');
		}
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 24);
	}, []);

	useEffect(() => {
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadTLPs();
	}, [regionId, allocationId]);

	useEffect(() => {
		loadCPs();
		resetAllFilters();
	}, [tlpId, date, regionId, allocationId, start_date, end_date, start_time,end_time,filterDate]);

	const resetAllFilters = () => {
		setSeries([]);
		setFilterVDC1(false);
		setFilterVAC1(false);
		setFilterVDC2(false);
		setFilterVAC1(false);
		setFilterVAC2(false);
		setFilterBattery(false);
		setFilterCurrent(false);
		setFilterTemp(false);
		setFilterVDC1Min(false);
		setFilterVDC1Max(false);
		setFilterVAC1Min(false);
		setFilterVAC1Max(false);
		setFilterVDC2Min(false);
		setFilterVDC2Max(false);
		setFilterVAC2Min(false);
		setFilterVAC2Max(false);
	};

	const loadTLPs = () => {
		dispatch({ type: TLPS_LIST });

		const limit = 10000;
		const skip = 0;
		let url = `${api}/smarttlp-list?$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1`;

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}

		if ((regionId && regionId !== null) || (allocationId && allocationId !== null)) {
			url = `${api}/smarttlp-list?$limit=${limit}&$skip=${skip}&$sort[pipelineChainage]=-1${filterByregionIdPart}`;
		}

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config) .then((response) => {
			if (response && response.data) {
				setTLPId(response.data && response.data.data && response.data.data[0] && response.data.data[0].deviceId);
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content:  langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadCPs = () => {
		if (!tlpId && date !== moment().format('YYYY-MM-DD')) {
			Modal.warning({ title: 'Please select a chainage' });
		}
		dispatch({ type: DAILY_CPS_LIST });

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}
    
		if (tlpId && tlpId !== null) {
			let url = `${api}/tlplogsbulkcpsdatefiltered?$limit=100000&tlpId=${tlpId}&startDate=${companyType == 'iocl' ? `${moment(filterDate).format('YYYY-MM-DD')}` : start_date}&startTime=${companyType == 'iocl' ? `${moment(filterDate.setHours(currentHour,0,0)).format('HH:mm:ss')}.000000` : start_time}&endTime=${companyType == 'iocl' ? `${moment(filterDate.setHours(currentHour,59,59)).format('HH:mm:ss')}.000000` :end_time}&endDate=${companyType == 'iocl' ? `${moment(filterDate).format('YYYY-MM-DD')}` : end_date}${filterByregionIdPart}`;
			const config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: url,
				timeout: timeout,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${authtoken}`
				}
			};
  
			axios.request(config) .then((response) => {
				if (response && response.data) {
					dispatch( { type: DAILY_CPS_LIST_SUCCESS, payload: response.data });
				} else {
					dispatch( { type: DAILY_CPS_LIST_ERROR });
				}
			}).catch((error) => {
				console.log(error);
				dispatch( { type: DAILY_CPS_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
		} else {
			dispatch( { type: DAILY_CPS_LIST_ERROR });
		}
	};

	const onChangeVDC2 = () => {
		let newVal = !filterVDC2;
		let fields = series;

		setFilterVDC2(newVal);
		onChangeVDC2Min(newVal);
		onChangeVDC2Max(newVal);

		if (newVal === true) {
			fields.push({name: companyType == 'iocl' ? 'OFF PSP-DC' : 'PSP (V)', data: referenceCellValue === true ? vdc2Refs : vdc2NoRefs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'OFF PSP-DC' : 'PSP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVAC2 = () => {
		let newVal = !filterVAC2;
		let fields = series;

		setFilterVAC2(newVal);
		onChangeVAC2Min(newVal);
		onChangeVAC2Max(newVal);

		if (newVal === true) {
			fields.push({name:  companyType == 'iocl' ? 'OFF PSP-AC' : 'AC PICKUP (V)', data: vac2s});
		} else {
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'OFF PSP-AC' : 'AC PICKUP (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVDC1 = () => {
		let newVal = !filterVDC1;
		let fields = series;

		setFilterVDC1(newVal);
		onChangeVDC1Min(newVal);
		onChangeVDC1Max(newVal);

		if (newVal === true) {
			fields.push({name: companyType == 'iocl' ? 'ON PSP-DC' : 'CSP (V)', data: referenceCellValue === true ? vdc1Refs : vdc1NoRefs});
		} else {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-DC' : 'CSP (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);  
	};

	const onChangeVAC1 = () => {      
		let newVal = !filterVAC1;
		let fields = series;

		setFilterVAC1(newVal);
		onChangeVAC1Min(newVal);
		onChangeVAC1Max(newVal);


		if (newVal === true) {
			fields.push({name: companyType == 'iocl' ? 'ON PSP-AC' : 'CASING AC PICKUP (V)', data: vac1s});
		} else {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-AC' : 'CASING AC PICKUP (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields); 
	};

	const onChangeBattery = () => {
		let newVal = !filterBattery;
		let fields = series;

		setFilterBattery(newVal);

		if (newVal === true) {
			fields.push({name: 'BATTERY (V)', data: vbats});
		} else {
			let index = fields.findIndex(function(item) { return item.name === 'BATTERY (V)';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeCurrent = () => {  
		let newVal = !filterCurrent;
		let fields = series;

		setFilterCurrent(newVal);

		if (newVal === true) {
			fields.push({name: companyType == 'iocl' ? 'Current DC mA'  : 'CURRENT', data: currents});
		} else {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'Current DC mA'  : 'CURRENT';});

			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);  
	};

	const onChangeTemp = () => {
		let newVal = !filterTemp;
		let fields = series;

		setFilterTemp(newVal);

		if (newVal === true) {
			fields.push({name: companyType == 'iocl' ? 'Current AC mA' :  'TEMPERATURE', data: temps});
		} else {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'Current AC mA' : 'TEMPERATURE';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		setSeries(fields);
	};

	const onChangeVAC1Min = (e) => { 
		let newVal = !filterVAC1Min;
		let fields = series;

		if(e !=='CASING AC PICKUP MIN'){
			setFilterVAC1Min(e);

		}else{
			setFilterVAC1Min(newVal);

		}


		if (newVal === true && e === 'CASING AC PICKUP MIN') {
			fields.push({name: companyType == 'iocl' ? 'ON PSP-AC MIN' : 'CASING AC PICKUP MIN (V)', data: vac1Mins});
		} else if (newVal === false && e === 'CASING AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-AC MIN' : 'CASING AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-AC MIN' : 'CASING AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: companyType == 'iocl' ? 'ON PSP-AC MIN' :  'CASING AC PICKUP MIN (V)', data: vac1Mins});

		}

		setSeries(fields);
	};

	const onChangeVAC1Max = (e) => { 
		let newVal = !filterVAC1Max;
		let fields = series;

		if(e !=='CASING AC PICKUP MAX'){
			setFilterVAC1Max(e);

		}else{
			setFilterVAC1Max(newVal);

		}


		if (newVal === true && e==='CASING AC PICKUP MAX') {
			fields.push({name: companyType == 'iocl' ? 'ON PSP-AC MAX' : 'CASING AC PICKUP MAX (V)', data: vac1Maxs});
		} else if(newVal === false && e==='CASING AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-AC MAX' : 'CASING AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-AC MAX' : 'CASING AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: companyType == 'iocl' ? 'ON PSP-AC MAX' : 'CASING AC PICKUP MAX (V)', data: vac1Maxs});

		}

		setSeries(fields);
	};

	const onChangeVDC1Min = (e) => {
		let newVal = !filterVDC1Min;
		let fields = series;

		if(e !=='CSP MIN'){
			setFilterVDC1Min(e);

		}else{
			setFilterVDC1Min(newVal);

		}

		if (newVal === true && e ==='CSP MIN') {
			fields.push({name:  companyType == 'iocl' ? 'ON PSP-DC MIN' : 'CSP MIN (V)', data: vdc1Mins});
		} else if(newVal === false && e ==='CSP MIN') {
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'ON PSP-DC MIN' :  'CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'ON PSP-DC MIN' :  'CSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}

		}else if (e ===true && newVal ===true){
			fields.push({name:  companyType == 'iocl' ? 'ON PSP-DC MIN' :  'CSP MIN (V)', data: vdc1Mins});

		}

		setSeries(fields);
	};

	const onChangeVDC1Max = (e) => { 
		let newVal = !filterVDC1Max;
		let fields = series;

		if(e !=='CSP MAX'){
			setFilterVDC1Max(e);

		}else{
			setFilterVDC1Max(newVal);

		}


		if (newVal === true && e ==='CSP MAX') {
			fields.push({name: companyType == 'iocl' ? 'ON PSP-DC MAX' :  'CSP MAX (V)', data: vdc1Maxs});
		} else if(newVal === false && e ==='CSP MAX') {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-DC MAX' : 'CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'ON PSP-DC MAX' : 'CSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name: companyType == 'iocl' ? 'ON PSP-DC MAX' : 'CSP MAX (V)', data: vdc1Maxs});

		}

		setSeries(fields);
	};

	const onChangeVAC2Min = (e) => { 
		let newVal = !filterVAC2Min;
		let fields = series;

		if(e !=='AC PICKUP MIN'){

			setFilterVAC2Min(e);
		}else{
			setFilterVAC2Min(newVal);

		}


		if (newVal === true && e ==='AC PICKUP MIN') {
			fields.push({name: companyType == 'iocl' ? 'OFF PSP-AC MIN' :  'AC PICKUP MIN (V)', data: vac2Mins});
		} else if (newVal === false && e ==='AC PICKUP MIN') {
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'OFF PSP-AC MIN' :   'AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name === companyType == 'iocl' ? 'OFF PSP-AC MIN' :  'AC PICKUP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if(e ===true && newVal ===true){
			fields.push({name: companyType == 'iocl' ? 'OFF PSP-AC MIN' : 'AC PICKUP MIN (V)', data: vac2Mins});

		}

		setSeries(fields);
	};

	const onChangeVAC2Max = (e) => { 
		let newVal = !filterVAC2Max;
		let fields = series;

		if(e !== 'AC PICKUP MAX'){
			setFilterVAC2Max(e);

		}else{
			setFilterVAC2Max(newVal);

		}


		if (newVal === true && e ==='AC PICKUP MAX') {
			fields.push({name: companyType == 'iocl' ? 'OFF PSP-AC MAX' :   'AC PICKUP MAX (V)', data: vac2Maxs});
		} else if(newVal === false && e ==='AC PICKUP MAX') {
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'OFF PSP-AC MAX' : 'AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'OFF PSP-AC MAX' : 'AC PICKUP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if(e ===true && newVal ===true){
			fields.push({name:  companyType == 'iocl' ? 'OFF PSP-AC MAX' : 'AC PICKUP MAX (V)', data: vac2Maxs});

		}

		setSeries(fields);
	};

	const onChangeVDC2Min = (e) => { 
		let newVal = !filterVDC2Min;
		let fields = series;
		
		if(e !=='PSP MIN'){
			setFilterVDC2Min(e);

		}else{
			setFilterVDC2Min(newVal);

		}


		if (newVal === true && e === 'PSP MIN') {
			fields.push({name:  companyType == 'iocl' ? 'OFF PSP-DC MIN' : 'PSP MIN (V)', data: vdc2Mins});
		} else if(newVal === false && e === 'PSP MIN') {
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ?'OFF PSP-DC MIN' : 'PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'OFF PSP-DC MIN' : 'PSP MIN (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}
		else if (e ===true && newVal ===true){
			fields.push({name:  companyType == 'iocl' ? 'OFF PSP-DC MIN' : 'PSP MIN (V)', data: vdc2Mins});

		}
		setSeries(fields);
	};

	const onChangeVDC2Max = (e) => { 
		let newVal = !filterVDC2Max;
		let fields = series;

		if(e !=='PSP MAX'){
			setFilterVDC2Max(e);

		}else{
			setFilterVDC2Max(newVal);

		}


		if (newVal === true && e === 'PSP MAX') {
			fields.push({name:  companyType == 'iocl' ? 'OFF PSP-DC MAX' : 'PSP MAX (V)', data: vdc2Maxs});
		} else if (newVal === false && e === 'PSP MAX') {
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'OFF PSP-DC MAX' :'PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}

		if(e===false && newVal===false){
			let index = fields.findIndex(function(item) { return item.name ===  companyType == 'iocl' ? 'OFF PSP-DC MAX' :'PSP MAX (V)';});
			if ( index >= 0) {
				fields.splice(index, 1);
			}
		}else if (e ===true && newVal ===true){
			fields.push({name: companyType == 'iocl' ?'OFF PSP-DC MAX' : 'PSP MAX (V)', data: vdc2Maxs});

		}

		setSeries(fields);
	};

	const handleReferenceCellValue = () => {
		let newVal  = referenceCellValue === true ? false : true;
		let fields = series;
		// let cspIndex = fields.findIndex(function(item) { return item.name === 'CSP (V)';});
		// let pspIndex = fields.findIndex(function(item) { return item.name === 'PSP (V)';});

		let cspIndex;
		fields.map((item, index) => {
			if(item.name === companyType == 'iocl' ? 'ON PSP-DC' : 'CSP (V)') {
				cspIndex = index;
			}
		});

		let pspIndex;
		fields.map((item, index) => {
			if(item.name === companyType == 'iocl' ? 'OFF PSP-DC' : 'PSP (V)') {
				pspIndex = index;
			}
		});

		setReferenceCellValue(newVal);
    
		if (newVal === true) {
			if (cspIndex >= 0) {
				// fields.splice(cspIndex, 1);
				// fields.push({name: 'CSP (V)', data: vdc1Refs});
				fields[cspIndex] = {name:  companyType == 'iocl' ? 'ON PSP-DC' :  'CSP (V)', data: vdc1Refs};
			}

			if (pspIndex >= 0) {
				// fields.splice(pspIndex, 1);
				// fields.push({name: 'PSP (V)', data: vdc2Refs});
				fields[pspIndex] = {name: companyType == 'iocl' ? 'OFF PSP-DC' :  'PSP (V)', data: vdc2Refs};
			} 
		} else {
			if (cspIndex >= 0) {
				// fields.splice(cspIndex, 1);
				// fields.push({name: 'CSP (V)', data: vdc1NoRefs});
				fields[cspIndex] = {name:  companyType == 'iocl' ? 'ON PSP-DC' :  'CSP (V)', data: vdc1NoRefs};

			}

			if (pspIndex >= 0) {
				// fields.splice(pspIndex, 1);
				// fields.push({name: 'PSP (V)', data: vdc2NoRefs});
				fields[pspIndex] = {name: companyType == 'iocl' ? 'OFF PSP-DC' : 'PSP (V)', data: vdc2NoRefs};
			}
		}

		setSeries(fields);
	};

	if (data && data.cps) {
		data.cps.map((item) => {
			timestamps.push(moment(item.datetime).format('DD/MM/YYYY hh:mm:ss A'));

			vdc1NoRefs.push(item.vdc1);
			// vdc1Refs.push((referenceCellValue ==false ? parseFloat(item.vdc1) : parseFloat(item.vdc1) + -1.1).toFixed(4));
			vdc1Refs.push((parseFloat(item.vdc1) + -1.1).toFixed(4));

			vdc2NoRefs.push(item.vdc2);
			// vdc2Refs.push((referenceCellValue == false ? parseFloat(item.vdc2) : parseFloat(item.vdc2) + -1.1).toFixed(4));
			vdc2Refs.push((parseFloat(item.vdc2) + -1.1).toFixed(4));

			vac1s.push(item.vac1);
			vac2s.push(item.vac2);
			vbats.push(item.vbat);
			temps.push(item.temp);
			currents.push(item.current);

			// Limit filters
			vac1Mins.push(item.vac1Min);
			vac1Maxs.push(item.vac1Max);
			vdc1Mins.push(item.vdc1Min);
			vdc1Maxs.push(item.vdc1Max);
			vac2Mins.push(item.vac2Min);
			vac2Maxs.push(item.vac2Max);
			vdc2Mins.push(item.vdc2Min);
			vdc2Maxs.push(item.vdc2Max);
		});
	}

	// const handleSetAnomaly = (e) =>{
		
	// 	setAnomalyValue(e);
	// 	if(e==false){
	// 		setTLPParams('&anomaly=false');

	// 	}else{
	// 		setTLPParams('&anomaly=true');
	// 	}

	// };

	let minChartWidth = parseInt(data.cps.length/20).toFixed(2);

	minChartWidth = (minChartWidth < 1) ? 1 : minChartWidth;

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	for (let h = 0; h < 24; h++) {
		hours.push({
			value : h,
			label : `${h}:00 - ${h}:59`
		});
	}

	const handleSetHour = (value) => {
		setCurrentHour(value);
	};
	
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={6}>
						<Row>
							<Space>
								<LineChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport :  'Daily CPS Report'}</Title>
						</Row>
					</Col>
					
					<Col span={18}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :  'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											defaultValue={null}
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbychainage ? langdata.Filterbychainage : 'Filter by chainage'}>
									<Space>
										<Select
											style={{ width: '120px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectChainage ? langdata.SelectChainage : 'Select Chainage'}
											optionFilterProp="children"
											value={tlpId}
											options={tlps}
											onSelect={handleChangeTLP}
											defaultValue={tlps && tlps[0]}
											disabled={tlps.length > 0 ? false : true}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbydate ? langdata.Filterbydate : 'Filter by date'}>
									<Space>
										<DatePicker style={{margin:'5px'}} allowClear={false} defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)} onChange={handleDate} disabledDate={disabledDate}  format={settingsdateformat}/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Space>
									<Tooltip placement="bottom" title={langdata && langdata.Filterbyhour ? langdata.Filterbyhour : 'Filter by hour'}>
										<Select
											style={{ width: '120px', margin:'5px', marginLeft : '10px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectHour ? langdata.SelectHour : 'Select Hour'}
											optionFilterProp="children"
											value={currentHour}
											options={hours}
											onSelect={handleSetHour}
										/>
									</Tooltip>
								</Space>
								<Space>&nbsp;&nbsp;</Space>
								<Popover title= {langdata && langdata.DailyCPSReport ? langdata.DailyCPSReport : 'Daily CPS Report'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={24} style={{ marginTop: '10px' }} >
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeVDC2} checked={filterVDC2}>{companyType == 'iocl' ?  langdata && langdata.OFFPSPDC ? langdata.OFFPSPDC : 'OFF PSP-DC' : langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC2Min('PSP MIN');}} checked={filterVDC2Min}>{companyType == 'iocl' ?  langdata && langdata.OFFPSPDCMIN ? langdata.OFFPSPDCMIN : 'OFF PSP-DC MIN' :  langdata && langdata.PSPMINV ? langdata.PSPMINV : 'PSP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC2Max('PSP MAX');}} checked={filterVDC2Max}>{companyType == 'iocl' ?  langdata && langdata.OFFPSPDCMAX ? langdata.OFFPSPDCMAX : 'OFF PSP-DC MAX' :  langdata && langdata.PSPMAXV ? langdata.PSPMAXV : 'PSP MAX (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={onChangeVAC2} checked={filterVAC2}>{ companyType == 'iocl' ?  langdata && langdata.OFFPSPAC ? langdata.OFFPSPAC : 'OFF PSP-AC' : langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC2Min('AC PICKUP MIN');}} checked={filterVAC2Min}>{companyType == 'iocl' ?  langdata && langdata.OFFPSPACMIN ? langdata.OFFPSPACMIN : 'OFF PSP-AC MIN' :  langdata && langdata.ACPICKUPMINV ? langdata.ACPICKUPMINV : 'AC PICKUP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC2Max('AC PICKUP MAX');}} checked={filterVAC2Max}>{companyType == 'iocl' ?  langdata && langdata.OFFPSPACMAX ? langdata.OFFPSPACMAX : 'OFF PSP-AC MAX' : langdata && langdata.ACPICKUPMAXV ? langdata.ACPICKUPMAXV : 'AC PICKUP MAX (V)'}</Checkbox>
							</Col>
						</Row>
						<br/>
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeVDC1} checked={filterVDC1}>{ companyType == 'iocl' ?  langdata && langdata.ONPSPDC ? langdata.ONPSPDC : 'ON PSP-DC' : langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC1Min('CSP MIN');}} checked={filterVDC1Min}>{companyType == 'iocl' ?  langdata && langdata.ONPSPDCMIN ? langdata.ONPSPDCMIN : 'ON PSP-DC MIN' :  langdata && langdata.CSPMINV ? langdata.CSPMINV : 'CSP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVDC1Max('CSP MAX');}} checked={filterVDC1Max}>{companyType == 'iocl' ?  langdata && langdata.ONPSPDCMAX ? langdata.ONPSPDCMAX : 'ON PSP-DC MAX' : langdata && langdata.CSPMAXV ? langdata.CSPMAXV : 'CSP MAX (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={onChangeVAC1} checked={filterVAC1}>{companyType == 'iocl' ?  langdata && langdata.ONPSPAC ? langdata.ONPSPAC : 'ON PSP-AC' : langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC1Min('CASING AC PICKUP MIN');}} checked={filterVAC1Min}>{companyType == 'iocl' ?  langdata && langdata.ONPSPACMIN ? langdata.ONPSPACMIN : 'ON PSP-AC MIN' : langdata && langdata.CASINGACPICKUPMINV ? langdata.CASINGACPICKUPMINV : 'CASING AC PICKUP MIN (V)'}</Checkbox>
							</Col>
							<Col>
								<Checkbox onChange={()=>{onChangeVAC1Max('CASING AC PICKUP MAX');}} checked={filterVAC1Max}>{companyType == 'iocl' ?  langdata && langdata.ONPSPACMAX ? langdata.ONPSPACMAX : 'ON PSP-AC MAX' : langdata && langdata.CASINGACPICKUPMAXV ? langdata.CASINGACPICKUPMAXV : 'CASING AC PICKUP MAX (V)'}</Checkbox>
							</Col>
						</Row>
						<br/>
						<Row style={{
							background: '#FFFFFF',
							alignItems:'center'
						}} >
							<Col>
								<Checkbox onChange={onChangeBattery} checked={filterBattery}>{langdata && langdata.BATTERY ? langdata.BATTERY : 'BATTERY'}</Checkbox>
							</Col>
							{tlp_feature.current ? 
								<Col>
									<Checkbox onChange={onChangeCurrent} checked={filterCurrent}>{companyType == 'iocl' ?  langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC mA' : langdata && langdata.CURRENT ? langdata.CURRENT : 'CURRENT'}</Checkbox>
								</Col> : null }
							<Col>
								<Checkbox onChange={onChangeTemp} checked={filterTemp}>{companyType == 'iocl' ?  langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC mA' : langdata && langdata.TEMPERATURE ? langdata.TEMPERATURE : 'TEMPERATURE'}</Checkbox>
							</Col>
						</Row>
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							{tlp_feature && tlp_feature.refElectrodeType!==2 ?
								<Col span={24}>
									<Text type="secondary">
										<Checkbox checked={referenceCellValue} onChange={handleReferenceCellValue}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
									</Text>
								</Col> : null }
						</Row>
						<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
							<Col span={24}>
								<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
								<br />
								<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
							</Col>
						</Row>
						<div  ref={ref}>
							<Card className="card-Shadow-dashboard" style={{overflow: 'scroll'}}>
								{ data.cps && data.cps.length > 0 && tlps.length >= 1 ? (
									<Chart height={500} width={(width - 40) * minChartWidth} options={ options } series={ series } type="line" />
								) : (
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								)}
							</Card>
						</div>
					</Col>
				</Row>
			</Content>
			<Spin fullscreen spinning={data.loading} />
		</>
	);
}