import moment from 'moment';
import { datetimeCovertertolocal } from '../../datetime-converter';
import { CPS_REPORT_LIST, CPS_REPORT_LIST_SUCCESS, CPS_REPORT_LIST_ERROR }  from './constants';
import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';

export default function cpsReportReducer(state, action) {
	let pagination = {};
	let cps = [];
	let tlps = [];
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const companyType = localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toLowerCase();
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);

 
	switch (action.type) {
	case TLPS_LIST:
		return { ...state, loading: true, error: false };

	case TLPS_LIST_SUCCESS:
		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				let data = {};

				data.id = item.id ? item.id : 0;
				data.deviceId = item.deviceId ? item.deviceId : 0;
				data.pipelineChainage = item.pipelineChainage ? parseFloat(item.pipelineChainage)/1000 : 0;

				tlps.push(data);
			});

			let sortedTLPS = tlps.sort(function(a, b) {
				return parseInt(a.pipelineChainage) - parseInt(b.pipelineChainage);
			});

			return { ...state, tlps: sortedTLPS, loading: false, error: false };
		} else {
			return { ...state, tlps: [], loading: false, error: true };
		}

	case TLPS_LIST_ERROR:
		return { ...state, loading: false, error: true };

	case CPS_REPORT_LIST:
		return { ...state, loading: true, error: false };

	case CPS_REPORT_LIST_SUCCESS:
		if (action.payload) {
			pagination.total = action.payload.total ? action.payload.total : 0;
			pagination.limit = action.payload.limit ? action.payload.limit : 10;
			pagination.skip = action.payload.skip ? action.payload.skip : 0;
			pagination.current = action.page ? action.page : 1;
			pagination.pageSize =  action.pageSize ? action.pageSize : 10;
		}

		if (action.payload && action.payload.data && action.payload.data.length > 0) {
			action.payload.data.map( (item) => {
				const datetime = datetimeCovertertolocal(item.smarttlp ?  item.smarttlp.lastSync : '<Not Set>');
				const newdate = datetime.localdate + ' ' +  datetime.localtime;
				const devicedatetime = datetimeCovertertolocal(item ?  item.datetime : '<Not Set>');
				const newdevicedate = devicedatetime.localdate + ' ' +  devicedatetime.localtime;
				let systemStatus = item.smarttlp.systemStatus;
				if(systemStatus) {
					for (let i = 0; i < systemStatus.length; i++) {
						if(systemStatus.includes(',')) {
							systemStatus = systemStatus.replace(',', ' | ');
						}
					}

					if(systemStatus.includes('vdc2')) {
						systemStatus = systemStatus.replace('vdc2', 'PSP');
					}
					if(systemStatus.includes('vdc1')) {
						systemStatus = systemStatus.replace('vdc1', 'CSP');
					}
					if(systemStatus.includes('vac1')) {
						systemStatus = systemStatus.replace('vac1', 'CASING AC PICKUP');
					}
					if(systemStatus.includes('vac2')) {
						systemStatus = systemStatus.replace('vac2', 'AC PICKUP');
					}
				}
						
				let data = {};

				data.id = item.id ? parseInt(item.id) : 0;
				data.pipelineChainage = item.smarttlp ?  parseFloat(item.smarttlp.pipelineChainage)/1000 : 0;
				data.deviceId = item.smarttlp ?  item.smarttlp.deviceId : 0;
				data.lastSync = item.smarttlp && item.smarttlp.lastSync  ? newdate : '-';
				data.vdc1 = item.vdc1 ? parseFloat(item.vdc1).toFixed(4) : 0;
				data.vac1 = item.vac1 ? parseFloat(item.vac1).toFixed(4) : 0;
				data.vdc2 = item.vdc2 ? parseFloat(item.vdc2).toFixed(4) : 0;
				data.vac2 = item.vac2 ? parseFloat(item.vac2).toFixed(4) : 0;
				data.vbat = item.vbat ? parseFloat(item.vbat).toFixed(2) : 0;
				data.temp = item.temp ? parseFloat(item.temp).toFixed(2) : 0;
				data.current = item.current ? parseFloat(item.current).toFixed(2) : 0;
				data.anomaly = item.anomaly == true  ?  'Anomaly' : 'Normal';
				data.systemStatus = systemStatus ? systemStatus : 'Normal' ;
				data.datetime = item.datetime ? companyType == 'iocl' ? moment.utc(item.datetime).format(settingsdateformat + ' ' + settingstimeformat) : newdevicedate : '-';
				data.secondVoltage = item.smarttlp.secondVoltage ? item.smarttlp.secondVoltage : 0;

				if(item.smarttlp && item.smarttlp.secondVoltage === false && companyType != 'iocl') {
					data.vdc1 = 'NA';
					data.vac1 = 'NA';
				}

				if(companyType == 'iocl' && item.anomaly == false) {
					data.vac2 = 'NA';
					data.vdc2 = 'NA';
					data.temp = 'NA';
					data.current = 'NA';
				}

				cps.push(data);
			});

			return { ...state, cps: cps, pagination: pagination, loading: false, error: false };
		} else {
			if (action.payload && action.payload.data && action.payload.data.length == 0) {
				return { ...state, cps: [], pagination: pagination, loading: false, error: false };
			} else {
				return { ...state, loading: false, error: true };
			}
		}

	case CPS_REPORT_LIST_ERROR:
		return { ...state, loading: false, error: true };

	default: {
		throw Error('Unknown action: ' + action.type);
	}
	}
}