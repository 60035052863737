/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import GoogleMapReact from 'google-map-react';
import { useAuth } from '../../Context';

import { Empty, Divider, Row, Card, Col, Select, Typography, Table, Avatar, Popover, Space, Button, Form, Modal, Checkbox, Tooltip, Radio, message, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InfoOutlined, DownloadOutlined } from '@ant-design/icons';

import { TLPS_LIST, TLPS_LIST_SUCCESS, TLPS_LIST_ERROR }  from './constants';
import { REALTIME_CPS_LIST, REALTIME_CPS_LIST_SUCCESS, REALTIME_CPS_LIST_ERROR } from './constants';
import { REALTIME_CPS_DETAILS, REALTIME_CPS_DETAILS_SUCCESS, REALTIME_CPS_DETAILS_ERROR } from './constants.js';

import realtimeCPSReducer from './reducer.js';
import {BatchDownloader} from './downloader';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';

import axios from 'axios';
import { LayersControl, MapContainer, Marker, Pane, Polyline, Popup, TileLayer } from 'react-leaflet';
import '../../../App.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Icon } from 'leaflet';
import { FundViewOutlined } from '@ant-design/icons';
import GoogleMap from '../../Map/map';
import moment from 'moment';


const pidsicon = new Icon({
	// iconUrl:'https://cdn-icons-png.flaticon.com/128/9187/9187564.png',
	iconUrl:require('../../../images/Refinery1.png'),
	iconSize: [30, 30],
	iconAnchor:[18, 30]
});

const { Title, Text} = Typography;


const days = [];
[...Array(60)].map((e,i) => {
	days.push({
		value:i+1,
		label:i+1
	});
});

const defaulticon = new Icon({
	// iconUrl:"https://cdn-icons-png.flaticon.com/128/9187/9187564.png",
	iconUrl:require('../../../images/markerimages/default-white.png'),
	iconSize: [30, 40],
	iconAnchor:[18, 36]
});

export default function RealtimeCPS() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const tlp_feature = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES` , null));
	const {getRegion, regiondata, getUserAllocation ,getUserAllocationData} = useAuth();
	const [selecteddays,setSelectedDays] =  useState(10);
	const date = new Date();
	const startdatetime = moment.utc(date.setHours(0,0,0,0)).subtract(selecteddays, 'days').toISOString();
	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));

	const companyType = localStorage.getItem(`${dashboardtype.toUpperCase()}_PIPELINE_NAME`).toLowerCase();
	//const companyType =  'iocl';
		
	const TLPtypes = [
		{
			value: 0,
			label: langdata && langdata.ALL ? langdata.ALL : 'ALL',
			params:''
		},
		{
			value: 3,
			label: companyType == 'iocl' ? langdata && langdata.OFFPSP ? langdata.OFFPSP : 'OFF PSP' : langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly',
			params:'&anomaly=true'
		},
		{	
			value: 10,
			label: langdata && langdata.BatteryCharged ? langdata.BatteryCharged : 'Battery Charged',
			params:'&hasSolarPanel=true&isTRU=false&batteryStatus=Charged'
		},
		{
			value: 11,
			label: langdata && langdata.BatteryReplaced ? langdata.BatteryReplaced : 'Battery Replaced',
			params:'&hasSolarPanel=false&isTRU=false&batteryStatus=Replaced'
		},
		{
			value: 12,
			label: langdata && langdata.DevicesNotSyncedinDays ? langdata.DevicesNotSyncedinDays : 'Devices Not Synced in (Days)',
			params:'&isTRU=false&lastSync[$lte]'
		},
		{
			value: 9,
			label: langdata && langdata.GoodBattery ? langdata.GoodBattery : 'Good Battery',
			params:'&batteryStatus=Good'
		},
		{
			value: 2,
			label:  companyType == 'iocl' ? langdata && langdata.ONPSP ? langdata.ONPSP : 'ON PSP' :  langdata && langdata.GoodTLP ? langdata.GoodTLP : 'Good TLP',
			params:'&anomaly=false'
		},
		{
			value: 13,
			label: langdata && langdata.HardwareConfigNotSynced ? langdata.HardwareConfigNotSynced : 'Hardware Config Not Synced',
			params:''
		},
		{
			value: 8,
			label: langdata && langdata.LowBattery ? langdata.LowBattery : 'Low Battery',
			params:'&batteryStatus=Low Battery'
		},
		{
			value: 7,
			label: langdata && langdata.SmartTLPs ? langdata.SmartTLPs : 'Smart TLPs',
			params:'&isTRU=false'
		},
		{
			value: 6,
			label: langdata && langdata.SmartTRUs ? langdata.SmartTRUs :  'Smart TRUs',
			params:'&isTRU=true'
		},
		{
			value: 14,
			label: langdata && langdata.SystemStatus ? langdata.SystemStatus : 'System Status',
			params:''
		},
		{
			value: 15,
			label: langdata && langdata.TamperedNoCoverageDevices ? langdata.TamperedNoCoverageDevices : 'Tampered / No Coverage Devices',
			params:'&isTampered=true'
		},
		{
			value: 4,
			label: langdata && langdata.TLPswithBattery ? langdata.TLPswithBattery : 'TLPs with Battery',
			params:'&hasSolarPanel=false&isTRU=false'
		},
		{
			value: 5,
			label: langdata && langdata.TLPswithSolarPanel ? langdata.TLPswithSolarPanel : 'TLPs with Solar Panel',
			params:'&hasSolarPanel=true&isTRU=false'
		},
		{
			value: 1,
			label: langdata && langdata.VulnerableTLP ? langdata.VulnerableTLP :  'Vulnerable TLP',
			params:'&isVulnerable=true'
		}
	];


	const systemStatus = [
		{
			value: 0,
			label: langdata && langdata.ALL ? langdata.ALL : 'ALL',
			params:''
		},
		{
			value: 1,
			label: langdata && langdata.NORMAL ? langdata.NORMAL : 'NORMAL',
			params: `&systemStatus=${null}`
		},
		{
			value: 2,
			label: companyType == 'iocl' ? langdata && langdata.OFFPSPDC ? langdata.OFFPSPDC : 'OFF PSP-DC'  :  langdata && langdata.PSPDC ? langdata.PSPDC : 'PSP DC',
			params:'&systemStatus[$ilike]=%vdc2%'
		},
		{
			value: 3,
			label: companyType == 'iocl' ?  langdata && langdata.ONPSPDC ? langdata.ONPSPDC : 'ON PSP-DC' : langdata && langdata.CSPDC ? langdata.CSPDC : 'CSP DC',
			params:'&systemStatus[$ilike]=%vdc1%'
		},
		{
			value: 4,
			label: companyType == 'iocl' ?  langdata && langdata.OFFPSPAC ? langdata.OFFPSPAC : 'OFF PSP-AC' : langdata && langdata.ACPICKUP ? langdata.ACPICKUP : 'AC PICKUP',
			params:'&systemStatus[$ilike]=%vac2%'
		},
		{
			value: 5,
			label: companyType == 'iocl' ? langdata && langdata.ONPSPAC ? langdata.ONPSPAC : 'ON PSP-AC' :  langdata && langdata.CASINGACPICKUP ? langdata.CASINGACPICKUP : 'CASING AC PICKUP',
			params:'&systemStatus[$ilike]=%vac1%'
		}
	];

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p><b>{langdata && langdata.RealtimeCPS ? langdata.RealtimeCPS : 'Realtime CPS'}</b>: {langdata && langdata.RealtimeCPSContent1 ? langdata.RealtimeCPSContent1 : 'This module provides a real-time view of the Smart TLPs installed on the pipeline. It displays all the Smart TLPs along with their most recently updated values.'}</p>
			<br/>
		</Card>
	);
	
	const { state } = useLocation();

	const [regionId, setRegionId] = useState(state && state.data.regionId ? state && state.data.regionId : null);
	const [tlpId, setTLPId] = useState(null);
  
	const [url, setURL] = useState(null);

	const initalState = { cps: [], pagination: { total: 0, limit: 10, skip: 0, current:1 }, details: {}, loading: true, error: false };
	const [data, dispatch] = useReducer(realtimeCPSReducer, initalState);
	const [showModal, setShowModal] = useState(false);
	const [viewType, setViewType] = useState('LIST_VIEW');

	const [referenceCellValue, setReferenceCellValue] = useState(tlp_feature==null ? false : tlp_feature && tlp_feature.refElectrodeType && tlp_feature.refElectrodeType==2 ? false : tlp_feature && tlp_feature.refElectrodeType==2 ? false : true);
	const [tlptype, setTLPType] = useState(state && state.data.value== 0 ? 0 : state && state.data.value ? state.data.value : 2);
	const [checkanomaly, setAnomalyValue] = useState(state && state.data.value ==3 ? true : false);
	const [tlpParams, setTLPParams] = useState(state && state.data.params== '' ? '' : state && state.data.params ? state.data.params : '&anomaly=false');	
	const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const [allocationId, setAllocationId] = useState(null);
	const [startCh, setStartCh] = useState(null);
	const [endCh, setEndCh] = useState(null);
	const [systemSystem, setSystemStatusType] = useState(0);

	const center = [];
	const TLP = [];
	const pipecolor = { color: 'black' };
	const odmetercolor = { color: '#036CD6' };
	const bordercolor = { color: '#fc8383'};

	const [showFetchAndDownloadModal, setShowFetchAndDownloadModal] = useState(false);

	const {handleGetMapdata, mapdata} = useAuth();
	const map_type= localStorage.getItem(`${dashboardtype.toUpperCase()}_MAP_TYPE`);
	const allPidsData = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_PIDS`));

	let regions = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL'}];
	let tlps = [{ value: null, label: langdata && langdata.ALL ? langdata.ALL : 'ALL' }];

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const navigate = useNavigate();

	const TLPicon = new Icon({
		iconUrl:require('../../../images/tlp.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36] });

	const smartTLPicon = new Icon({
		iconUrl:require('../../../images/tlpred.png'),
		iconSize: [30, 40],
		iconAnchor:[18, 36] });

	const columns = [
		{
			title: langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)',
			dataIndex: 'pipelineChainage',
			key: 'pipelineChainage',
			render: (_, record) => (
				<>
					{/* TRU */}
					{ record.isTRU  && 
          <Space size="middle"><div><div style={{ float: 'left', backgroundColor: 'red', width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} /><a onClick={openShowModal} style={{ marginTop: '10px', float: 'left' }}>{record.pipelineChainage}</a></div></Space>
					}

					{/* TLP with solar panel */}
					{ !record.isTRU && record.hasSolarPanel &&
          <Space size="middle"><div><div style={{ float: 'left', backgroundColor: 'green', width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} /><a onClick={openShowModal} style={{ marginTop: '10px', float: 'left' }}>{record.pipelineChainage}</a></div></Space>
					}

					{/* TLP  with battery */}
					{ !record.isTRU && !record.hasSolarPanel &&
          <Space size="middle"><div><div style={{ float: 'left', backgroundColor: 'gold', width: '3px', height: '40px', display: 'inline-block', marginRight: '10px', marginLeft: '-15px'}} /><a onClick={openShowModal} style={{ marginTop: '10px', float: 'left' }}>{record.pipelineChainage}</a></div></Space>
					}
				</>
			)
		},
		{
			title: langdata && langdata.LastSync ? langdata.LastSync : 'Last Sync',
			dataIndex: 'lastSync',
			key: 'lastSync',
		},
		{
			title: companyType == 'iocl' ?  langdata && langdata.OFFPSPAC ? langdata.OFFPSPAC : 'OFF PSP-AC' : langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)',
			dataIndex: 'vac2',
			key: 'vac2',
		},
		{
			title: companyType == 'iocl' ?  langdata && langdata.OFFPSPDC ? langdata.OFFPSPDC : 'OFF PSP-DC' : langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)',
			dataIndex: 'vdc2',
			key: 'vdc2',
			render : (_,record) => (
				<>
					{referenceCellValue === false ? record.vdc2 : (parseFloat(record.vdc2) + -1.1).toFixed(4)}
				</>
			)
		},
		{
			title: companyType == 'iocl' ?  langdata && langdata.ONPSPAC ? langdata.ONPSPAC : 'ON PSP-AC' : langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)',
			dataIndex: 'vac1',
			key: 'vac1',
		},
		{
			title: companyType == 'iocl' ?  langdata && langdata.ONPSPDC ? langdata.ONPSPDC : 'ON PSP-DC' : langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)',
			dataIndex: 'vdc1',
			key: 'vdc1',
			render : (_,record) => (
				<>
					{referenceCellValue === false || record.secondVoltage === 0 ? record.vdc1 : (parseFloat(record.vdc1) + -1.1).toFixed(4)}
				</>
			)
		},
		{
			title: langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)',
			dataIndex: 'vbat',
			key: 'vbat',
		},
		{
			title: companyType == 'iocl' ?  langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC mA' :  langdata && langdata.Current ? langdata.Current : 'Current',
			dataIndex: 'current',
			key: 'current',
		},
		{
			title: companyType == 'iocl' ?  langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC mA' : langdata && langdata.Temp ? langdata.Temp : 'Temp',
			dataIndex: 'temp',
			key: 'temp',
		},
		companyType == 'iocl' ? {
			title: langdata && langdata.PSPType ? langdata.PSPType : 'PSP Type',
			dataIndex: 'anomaly',
			key: 'anomaly',
			render: (_, { anomaly }) => (
				anomaly == 'Normal' ?
					(
						<div style={{ color: 'green' }}>
							{'ON PSP'}
						</div>
					)
					:
					(
						<div style={{ color: 'crimson' }}>
							{'OFF PSP'}

						</div>
					)
			)
		}
			:
			{
				title: langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly',
				dataIndex: 'anomaly',
				key: 'anomaly',
				render: (_, { anomaly }) => (
					anomaly == 'Normal' ?
						(
							<div style={{ color: 'green' }}>
								{ langdata && langdata.Normal ? langdata.Normal : 'Normal'}
							</div>
						)
						:
						(
							<div style={{ color: 'crimson' }}>
								{langdata && langdata.Anomaly ? langdata.Anomaly : 'Anomaly'}
							</div>
						)
				)
			},
		{
			title: langdata && langdata.SystemStatus ? langdata.SystemStatus : 'System Status',
			dataIndex: 'systemStatus',
			key: 'systemStatus',
		},

	];

	let allPidsDatas =[];

	if(allPidsData.length>0) {
		allPidsData && allPidsData.map((item) => {
			allPidsDatas.push({
				geocode:[item.latitude, item.longitude], 
				pop:item.label
			});
		});
	
	}

	const openShowModal = () => {
		setShowModal(true);
	};

	const closeShowModal = () => {
		setShowModal(false);
	};

	const showTLPDetails = () => {
		setShowModal(false);
		navigate(('/tlp-details'),{ state: { details : data && data.details } });
	};

	if(admin_roles.includes(permission)){
		regiondata && regiondata.map((region)=>{
			regions.push({
				value:region.id,
				label:region.regionName.toUpperCase()});
		});
	} else {
		getUserAllocationData && getUserAllocationData.map((region)=>{
			regions.push({
				value:region.id,
				label: region.regioninfo.regionName+'(' + region.pipelineConfigFrom+' - '+region.pipelineConfigTo + ')',
				region: region.regioninfoId,
				pipelineConfigFrom : region.pipelineConfigFrom,
				pipelineConfigTo : region.pipelineConfigTo
			});
		});
	}

	const handleChangeRegion = (e, options ) => {
		localStorage.setItem(`${dashboardtype}_REGION_ID`,e);
		if(permission == 'superadmin' || permission == 'admin') {
			setRegionId(e);
		} else {
			setAllocationId(e);
			setRegionId(options.region);
			setStartCh(options.pipelineConfigFrom);
			setEndCh(options.pipelineConfigTo);
		}
		setTLPId(null);
	};

	const handleChangeTLPType = (e,options) => {
		if(e==3){
			setAnomalyValue(true);
		}else{
			setAnomalyValue(false);
		}
		setTLPType(e);
		setTLPParams(options.params);
	};

	data && data.tlps && data.tlps.map((tlp) => {
		tlps.push({ value: tlp.deviceId, label: tlp.pipelineChainage.toString() });
	});

	const handleChangeTLP = (e) => {
		
		setTLPId(e);
	};

	const handleSetAnomaly = (e) =>{

		setAnomalyValue(e);
		if(e==false){
			setTLPType(2);
			setTLPParams('&anomaly=false');

		}else{
			setTLPType(3);
			setTLPParams('&anomaly=true');
		}

	};
  
	useEffect(() => {
		if(featuresModule.realtimeCPSEnabled == false) {
			navigate('/dashboard');
		}
		if(!authtoken){
			navigate('/');
		}
		if (map_type && map_type === 'leaflet') {
			handleGetMapdata(authtoken,regionId,startCh,endCh);
		}
		if(admin_roles.includes(permission)){
			getRegion(authtoken);
		}
		else{
			getUserAllocation(authtoken);
		}
		loadTLPs();
		if(tlptype==13){
			loadCPsHardwareUnsynced(1);
		}else{
			loadCPs(1);
		}
	}, [regionId,tlptype,checkanomaly,tlpParams, allocationId,selecteddays]);

	mapdata &&  mapdata.map((item,index)=>{
		if(index==0){
			center.push(item.latitude,item.longitude);
		}
	});

	mapdata &&  mapdata.map((item)=>{
		if(item.rouMarker=='TLP'){
			TLP.push({
				geocode:[item.latitude,item.longitude],
				pop:'TLP - ' + item.pipelineChainage / 1000,
				ic: item.tlpId ? smartTLPicon : TLPicon
			},);
		}

	});

	const tempMapArray = {};

	mapdata && mapdata.map((item) => {
		const fieldValue = item['regioninfoId'];
		if(!tempMapArray[fieldValue]) {
			tempMapArray[fieldValue] = [];
		}
		tempMapArray[fieldValue].push(item);
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , 21);
	}, []);

	useEffect(() => {
		if(tlptype==13){
			loadCPsHardwareUnsynced(1);
		}else{
			loadCPs(1);
		}
	}, [tlpId,tlptype,checkanomaly,tlpParams,selecteddays]);


	const loadTLPs = () => {
		dispatch({ type: TLPS_LIST });

		const limit = 10000;
		const skip = 0;
    
		let filterByregionIdPart = '';
		
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}

		let url = `${api}/smarttlp-list?${filterByregionIdPart}${tlpParams && tlpParams==='&isTRU=false&lastSync[$lte]' ? `&isTRU=false&lastSync[$lte]=${startdatetime}` : tlpParams}&$limit=${limit}&$skip=${skip}&pipelineChainageId[$gt]=0&$sort[pipelineChainage]=-1`;

		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: TLPS_LIST_SUCCESS, payload: response.data });
			} else {
				dispatch( { type: TLPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: TLPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};

	const loadCPs = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;

		dispatch({ type: REALTIME_CPS_LIST });

		let filterByregionIdPart = '';
		
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}
    
		let filterByDeviceIdPart = '';
    
		if (tlpId && tlpId !== null) {
			filterByDeviceIdPart = `&deviceId=${tlpId}`;
		} else {
			filterByDeviceIdPart = '';
		}
    
		let url = `${api}/smarttlp-list?${filterByregionIdPart}${filterByDeviceIdPart}${tlpParams && tlpParams==='&isTRU=false&lastSync[$lte]' ? `&isTRU=false&lastSync[$lte]=${startdatetime}` : tlpParams}&pipelineChainageId[$gt]=0&$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[pipelineChainageId]=1`;
    
		setURL({base: `${api}/smarttlp-list`, params: `${filterByregionIdPart}${filterByDeviceIdPart}${tlpParams && tlpParams==='&isTRU=false&lastSync[$lte]' ? `&isTRU=false&lastSync[$lte]=${startdatetime}` : tlpParams}&$sort[pipelineChainageId]=1`});
    
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: REALTIME_CPS_LIST_SUCCESS, payload: response.data , page:pagination.current});
			} else {
				dispatch( { type: REALTIME_CPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: REALTIME_CPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};


	const loadCPsHardwareUnsynced = (pagination) => {
		let _pagination = { };
    
		_pagination.total = data.pagination.total ? data.pagination.total : 0;
		_pagination.limit = pagination.pageSize ? pagination.pageSize : 10;
		_pagination.skip =  pagination && pagination.current    ? parseInt((pagination.current-1) * pagination.pageSize) : 0;
		_pagination.pageSize =  pagination.pageSize ? pagination.pageSize : 10;

		dispatch({ type: REALTIME_CPS_LIST });

		let filterByregionIdPart = '';
		if(admin_roles.includes(permission)){
			filterByregionIdPart = regionId ? `&regioninfoId=${regionId}` : '';
		}
		else{
			filterByregionIdPart = allocationId ? `&allocationId=${allocationId}` : '';
		}
    
		let filterByDeviceIdPart = '';
    
		if (tlpId && tlpId !== null) {
			filterByDeviceIdPart = `&deviceId=${tlpId}`;
		} else {
			filterByDeviceIdPart = '';
		}
    
		let url = `${api}/smarttlp-list-config-not-synced?${filterByregionIdPart}${filterByDeviceIdPart}&pipelineChainageId[$gt]=0&$limit=${_pagination.limit}&$skip=${_pagination.skip}&$sort[pipelineChainageId]=1`;
    
		setURL({base: `${api}/smarttlp-list-config-not-synced`, params: `${filterByregionIdPart}${filterByDeviceIdPart}&$sort[pipelineChainageId]=1`});
    
		const config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: url,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json', 
				'Authorization': `Bearer ${authtoken}`
			}
		};

		axios.request(config) .then((response) => {
			if (response && response.data) {
				dispatch( { type: REALTIME_CPS_LIST_SUCCESS, payload: response.data, page:pagination.current, pageSize : pagination.pageSize  });
			} else {
				dispatch( { type: REALTIME_CPS_LIST_ERROR });
			}
		}).catch((error) => {
			console.log(error);
			dispatch( { type: REALTIME_CPS_LIST_ERROR });
			if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
				message.config({
					duration : 5,
					maxCount : 1,
				});
				message.open({
					type: 'error',
					content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
				});
			}
		});
	};
	
	const loadCPSDetails = ((id) =>  {
		let details = null;

		dispatch({ type: REALTIME_CPS_DETAILS });

		if (data && data.cps) {
			data.cps && data.cps.map((item) => {
				if (item.id === id) {
					details = item;
				}
			});
      
			dispatch({ type: REALTIME_CPS_DETAILS_SUCCESS, payload: details });
		} else {
			dispatch({ type: REALTIME_CPS_DETAILS_ERROR });
		}
	});

	const openShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(true);
	};

	const closeShowFetchAndDownloadModal = () => {
		setShowFetchAndDownloadModal(false);
	};

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const handleSelectDays = (e)=>{
		setSelectedDays(e);
	};
	
	const handleSelectStatus = (e,options)=>{
		setSystemStatusType(e);
		setTLPParams(options.params);
	};

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					marginBottom: '0px',
					paddingTop: '10px',
					paddingBottom: '10px',
					background: '#FFFFFF',
					alignItems:'right',
				}} >

					<Col span={8}>
						<Row style={{paddingLeft: '10px'}}>
							<Space>
								<FundViewOutlined style={{fontSize:'30px'}}/>
							</Space>
							<Space>&nbsp;&nbsp;</Space>
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.RealtimeCPS ? langdata.RealtimeCPS : 'Realtime CPS'}</Title>
							<Space>&nbsp;&nbsp;</Space>
							<Space>
								<Radio.Group style={{margin:'5px'}} value={viewType} onChange={(e) => setViewType(e.target.value)}>
									<Radio.Button value="LIST_VIEW">{langdata && langdata.List ? langdata.List : 'List'}</Radio.Button>
									<Radio.Button value="MAP_VIEW">{langdata && langdata.Map ? langdata.Map : 'Map'}</Radio.Button>
								</Radio.Group>
							</Space>
						</Row>
					</Col>

					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyregion ? langdata.Filterbyregion :  'Filter by region'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectRegion ? langdata.SelectRegion : 'Select Region'}
											optionFilterProp="children"
											value={ admin_roles.includes(permission) ? regionId : allocationId }
											options={regions}
											onSelect={(e, options)=>handleChangeRegion(e, options)}
										/>  
									</Space>
								</Tooltip>
								<Space>&nbsp;&nbsp;</Space>
								{viewType === 'LIST_VIEW' ? 
									<>
										<Tooltip placement="bottom" title={langdata && langdata.FilterbyTLPtype ? langdata.FilterbyTLPtype : 'Filter by TLP type'}>
											<Space>
												<Select
													style={{ width: '230px',margin:'5px'}}
													showSearch
													filterOption={filterOption}
													placeholder={langdata && langdata.SelectType ? langdata.SelectType : 'Select Type'}
													optionFilterProp="children"
													value={tlptype}
													options={TLPtypes}
													onSelect={(e,options)=>{handleChangeTLPType(e,options);}}
												/>
											</Space>
										</Tooltip>
										{tlptype == 12 ? 
											<Tooltip placement="bottom" title={langdata && langdata.NumberOfDays ? langdata.NumberOfDays : 'Number Of Days'}>
												<Space>&nbsp;&nbsp;</Space>
												<Space>
													<Select
														style={{ width: '60px',margin:'5px'}}
														showSearch
														filterOption={filterOption}
														placeholder= {langdata && langdata.SelectNumberOfDays ? langdata.SelectNumberOfDays : 'Select Number Of Days'}
														optionFilterProp="children"
														value={selecteddays}
														options={days}
														onSelect={(e)=>{handleSelectDays(e);}}
													/>
												</Space>
											</Tooltip> :null}
										{tlptype == 14 ? 
											<Tooltip placement="bottom" title={langdata && langdata.SystemStatus ? langdata.SystemStatus : 'System Status'}>
												<Space>&nbsp;&nbsp;</Space>
												<Space>
													<Select
														style={{ width: '120px',margin:'5px'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.SelectStatus ? langdata.SelectStatus : 'Select Status'}
														optionFilterProp="children"
														value={systemSystem}
														options={systemStatus}
														onSelect={(e,options)=>{handleSelectStatus(e,options);}}
													/>
												</Space>
											</Tooltip> :null}
										{tlptype !==13 ?
											<Tooltip placement="bottom" title={langdata && langdata.Filterbychainage ? langdata.Filterbychainage : 'Filter by chainage'}>
												<Space>&nbsp;&nbsp;</Space>

												<Space>
													<Select
														style={{ width: '120px', margin:'5px'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.SelectChainage ? langdata.SelectChainage : 'Select Chainage'}
														optionFilterProp="children"
														defaultValue={'ALL'}
														value={tlpId}
														options={tlps}
														onSelect={handleChangeTLP}
													/>
												</Space>
											</Tooltip> : null
										}
									</> : null }
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.RealtimeCPS ? langdata.RealtimeCPS : 'Realtime CPS'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
								<Space>&nbsp;&nbsp;</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center',
				}} >
					{/* List View Starts */}
					{ viewType === 'LIST_VIEW' ? 
						<Col span={24}>
							<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
								<Col span={4}>
									<Text type="secondary" style={{ borderLeft: '10px solid gold', paddingLeft: '10px' }}>{langdata && langdata.TLPwithBattery ? langdata.TLPwithBattery : 'TLP with Battery'}</Text>
								</Col>
								<Col span={4}>
									<Text type="secondary" style={{ borderLeft: '10px solid green', paddingLeft: '10px' }}>{langdata && langdata.TLPwithSolarPanel ? langdata.TLPwithSolarPanel : 'TLP with Solar Panel'}</Text>
								</Col>
								<Col span={4}>
									<Text type="secondary" style={{ borderLeft: '10px solid crimson', paddingLeft: '10px' }}>{langdata && langdata.TRU ? langdata.TRU : 'TRU'}</Text>
								</Col>
							</Row>
							<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
								{tlp_feature && tlp_feature.refElectrodeType!==2 ?

									<Col span={12}>
										<Text type="secondary">
											<Checkbox checked={referenceCellValue} onChange={()=> { referenceCellValue === true ? setReferenceCellValue(false) : setReferenceCellValue(true);}}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
										</Text>
									</Col> : null }
								<Col span={12}>
									<Text type="secondary">
										<Checkbox checked={checkanomaly} onChange={()=> {handleSetAnomaly(checkanomaly === true ? false : true);}}>{companyType == 'iocl' ? langdata && langdata.ShowOFFPSP ? langdata.ShowOFFPSP : 'Show OFF PSP' : langdata && langdata.Showanomaly ? langdata.Showanomaly : 'Show anomaly'}</Checkbox>
									</Text>
								</Col>

							</Row>
							<Row>
								<Col span={24}>
									<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
									<br />
									<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
								</Col>
							</Row>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems:'center'
							}} >
								<Col span={24}>
									{ data && data.cps ? (
										<Table
											scroll={{
												x: 900,
											}} 
											columns = { columns } 
											dataSource = { data.cps } 
											pagination = { data.cps.length > 0 ? data.pagination : 0 }
											onChange = { loadCPs }
											onRow={(record) => {
												return {
													onClick: () => { 
														loadCPSDetails(record.id);
													}
												};
											}}
										/>
									)
										:
										(
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										)}
								</Col>
							</Row>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems:'center'
							}} >
								<Col span={24}>
									<Button disabled={data.cps && data.cps.length > 0 ? false : true} type="primary" title={langdata && langdata.DownloadReport ? langdata.DownloadReport : 'Download Report'} onClick={openShowFetchAndDownloadModal}><DownloadOutlined />{langdata && langdata.Download ? langdata.Download : 'Download'}</Button>
								</Col>
							</Row>
						</Col>
						: null }
					{/* List View Ends */}
					{ viewType === 'MAP_VIEW' ?
						map_type && map_type !== 'leaflet' ?

							< GoogleMap TLPData = {'TLPData'} regionId={regionId} startCh={startCh} endCh={endCh}/>

							:

							<Content>
								<div className="App" style={{textAlign: 'left', margin : '10px'}}>
									<MapContainer center={center} zoom={14} scrollWheelZoom={false}>
										<TileLayer
											attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
											url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
										/>
										<LayersControl position="topright" >
											<MarkerClusterGroup>
												{allPidsDatas && allPidsDatas.map((marker, index)=>
													<Marker key={`allPidsDatas-${index}`} position={marker.geocode} icon={pidsicon}>
														<Popup>
															{marker.pop}
														</Popup>

													</Marker>

	

												)}       
											</MarkerClusterGroup>
											<LayersControl.Overlay checked name="TLP">
												<MarkerClusterGroup>
													{TLP && TLP.map((marker,index)=>
														<Marker key={`tlp-${index}`}  position={marker.geocode} icon={marker && marker ? marker.ic : defaulticon}>
															<Popup>
																{marker.pop}
															</Popup>
															{/* <Tooltip>{marker.pop}</Tooltip> */}
														</Marker>

													)}       
												</MarkerClusterGroup>
											</LayersControl.Overlay>
											<LayersControl.Overlay  checked name="Pipeline">
												<MarkerClusterGroup>
													<Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
														{tempMapArray && Object.values(tempMapArray).map((item) => {
															let pipeline = [];
															item.map((data) => {
																pipeline.push({lat : data.latitude,lng : data.longitude});
															});
															return (
																<>
																	<Polyline pathOptions={pipecolor} zIndex = {1} weight={7} positions={pipeline} />
																</>
															);

														})}
													</Pane>
												</MarkerClusterGroup>
											</LayersControl.Overlay>

											<LayersControl.Overlay  name={langdata && langdata.PipelineBorder ? langdata.PipelineBorder : 'Pipeline Border'}>
												<MarkerClusterGroup>
													{tempMapArray && Object.values(tempMapArray).map((item) => {
														let pipelineBorder = [];
														item.map((data) => {
															pipelineBorder.push({lat : data.latitude,lng : data.longitude});
														});
														return (
															<>
																<Polyline opacity={0.35} pathOptions={bordercolor} zIndex = {2} weight={25} positions={pipelineBorder} />
															</>
														);

													})}
												</MarkerClusterGroup>

											</LayersControl.Overlay>

											<LayersControl.Overlay name={langdata && langdata.Fiber ? langdata.Fiber : 'Fiber'}>
												<MarkerClusterGroup>
													{tempMapArray && Object.values(tempMapArray).map((item) => {
														let odmeter = [];
														item.map((data) => {
															odmeter.push({lat : data.fibreLatitude,lng : data.fibreLongitude});
														});
														return (
															<>
																<Polyline pathOptions={odmetercolor} weight={10} positions={odmeter} />
															</>
														);

													})}
												</MarkerClusterGroup>
											</LayersControl.Overlay>
										</LayersControl>
									</MapContainer>
								</div>
							</Content>
						: null }
					{/* Map View Ends */}
				</Row>
			</Content>  

			<Modal
				title= {langdata && langdata.TLPDetails ? langdata.TLPDetails : 'TLP Details'}
				centered
				open={showModal}
				onOk={{closeShowModal}}
				onCancel={closeShowModal}
				footer={[
					<Button key="submit" type="primary" onClick={showTLPDetails}>{langdata && langdata.Moreinfo ? langdata.Moreinfo : 'MORE INFO'}</Button>
				]}
				width={640}
			>
				{tlp_feature && tlp_feature.refElectrodeType!==2 ?
					<Row style={{ marginTop: '10px', marginBottom: '10px' }}>
						<Col span={24} style={{ marginTop : '2%'}}>
							<Checkbox checked={referenceCellValue} onChange={()=> { referenceCellValue === true ? setReferenceCellValue(false) : setReferenceCellValue(true);}}> * {langdata && langdata.ShowregularisedvaluewrtCuCuSO4referencecell ? langdata.ShowregularisedvaluewrtCuCuSO4referencecell : 'Show regularised value w.r.t Cu-CuSO4 reference cell.'}</Checkbox>
						</Col> 
					</Row> : null }
				<Row>
					<Col span={24}>
						<Text type="secondary">{langdata && langdata.PSPVFieldobservedvalue ? langdata.PSPVFieldobservedvalue : 'PSP (V) = (Field observed value) + (-1.1)'}</Text>
						<br />
						<Text type="secondary">{langdata && langdata.CSPVFieldobservedvalue ? langdata.CSPVFieldobservedvalue : 'CSP (V) = (Field observed value) + (-1.1)'}</Text>
					</Col>
				</Row>
				<Divider />
				<Form layout="vertical">
					<Row gutter={16}>
						<Col span={24}>
							<label>{langdata && langdata.ChainageKms ? langdata.ChainageKms : 'Chainage (Kms)'}: {data && data.details && data.details.pipelineChainage}</label>
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.Deviceid ? langdata.Deviceid : 'Device id'}: {data && data.details && data.details.deviceId}</label>
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.Installatondate ? langdata.Installatondate : 'Installaton date'}: {data && data.details && data.details.installedDate}</label>
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.LastSync ? langdata.LastSync : 'Last Sync'}: {data && data.details && data.details.lastSync}</label>
						</Col>
						<Col span={24}>
							<label>{companyType == 'iocl' ?  langdata && langdata.OFFPSPAC ? langdata.OFFPSPAC : 'OFF PSP-AC' : langdata && langdata.ACPICKUPV ? langdata.ACPICKUPV : 'AC PICKUP (V)'}: {data && data.details && data.details.vac2}</label>
						</Col>
						<Col span={24}>
							<label>{companyType == 'iocl' ?  langdata && langdata.OFFPSPDC ? langdata.OFFPSPDC : 'OFF PSP-DC' : langdata && langdata.PSPV ? langdata.PSPV : 'PSP (V)'}: {referenceCellValue === false ? data && data.details && data.details.vdc2 : (parseFloat(data && data.details && data.details.vdc2) + -1.1).toFixed(4)}</label>
						</Col>
						<Col span={24}>
							<label>{companyType == 'iocl' ?  langdata && langdata.ONPSPAC ? langdata.ONPSPAC : 'ON PSP-AC' : langdata && langdata.CASINGACPICKUPV ? langdata.CASINGACPICKUPV : 'CASING AC PICKUP (V)'}: {data && data.details && data.details.vac1}</label>
						</Col>
						<Col span={24}>
							<label>{companyType == 'iocl' ?  langdata && langdata.ONPSPDC ? langdata.ONPSPDC : 'ON PSP-DC' : langdata && langdata.CSPV ? langdata.CSPV : 'CSP (V)'}: { referenceCellValue === false || data && data.details && data.details.secondVoltage === 0 ? data && data.details && data.details.vdc1 :  (parseFloat(data && data.details && data.details.vdc1) + -1.1).toFixed(4)}</label>
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.BatteryV ? langdata.BatteryV : 'Battery (V)'}: {data && data.details && data.details.vbat}</label>
						</Col>
						<Col span={24}>
							<label>{companyType == 'iocl' ?  langdata && langdata.CurrentDCmA ? langdata.CurrentDCmA : 'Current DC mA' : langdata && langdata.CurrentmA ? langdata.CurrentmA : 'Current (mA)'}: {data && data.details && data.details.current}</label>
						</Col>
						<Col span={24}>
							<label>{companyType == 'iocl' ?  langdata && langdata.CurrentACmA ? langdata.CurrentACmA : 'Current AC mA' : langdata && langdata.Temp ? langdata.Temp : 'Temp'}: {data && data.details && data.details.temp}</label>
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.Solarpanelavailable ? langdata.Solarpanelavailable : 'Solar panel available'}: </label>
							{ (data && data.details && data.details.hasSolarPanel)
								?
								'YES'
								:
								'NO'
							}
						</Col>
						{companyType == 'iocl' ? 
							<Col span={24}>
								<label>{langdata && langdata.PSPType ? langdata.PSPType : 'PSP Type'}: </label>
								{ (data && data.details && data.details.anomaly == 'Normal')
									?
									'ON PSP'
									:
									'OFF PSP'
								}
							</Col>
					
							:
							<Col span={24}>
								<label>{langdata && langdata.Anomalydetected ? langdata.Anomalydetected : 'Anomaly detected'}: </label>
								{ (data && data.details && data.details.anomaly == 'Normal')
									?
									'Normal'
									:
									'Anomaly'
								}
							</Col>
						}
						<Col span={24}>
							<label>{langdata && langdata.Isvulnerable ? langdata.Isvulnerable : 'Is vulnerable'}: </label>
							{ (data && data.details && data.details.isVulnerable)
								?
								'YES'
								:
								'NO'
							}
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.IsTRU ? langdata.IsTRU : 'Is TRU'}: </label>
							{ (data && data.details && data.details.isTRU)
								?
								'YES'
								:
								'NO'
							}
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.TRUvoltageV ? langdata.TRUvoltageV : 'TRU voltage (V)'}: {data && data.details && data.details.truVoltage}</label>
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.TRUcorrectionvoltageV ? langdata.TRUcorrectionvoltageV : 'TRU correction voltage (V)'}: {data && data.details && data.details.truCorrectionVoltage}</label>  
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.Latittude ? langdata.Latittude : 'Latittude'}: {data && data.details && data.details.latitude}</label> 
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.Longitude ? langdata.Longitude : 'Longitude'}: {data && data.details && data.details.longitude}</label> 
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.HardwareConfigSyncedOn ? langdata.HardwareConfigSyncedOn : 'Hardware Config Synced On'}: {data && data.details && data.details.hardwareConfigSyncedDate}</label> 
						</Col>
						<Col span={24}>
							<label>{langdata && langdata.BatteryReplacedOn ? langdata.BatteryReplacedOn : 'Battery Replaced On'}: {data && data.details && data.details.batteryReplacedDate}</label> 
						</Col>
					</Row>
				</Form>
			</Modal>

			{/* Fetch and Download Modal Starts */}
			<Modal
				title={langdata && langdata.DownloadReport ? langdata.DownloadReport : 'Download Report'}
				centered
				destroyOnClose={true}
				open={showFetchAndDownloadModal}
				onOk={{closeShowFetchAndDownloadModal}}
				onCancel={closeShowFetchAndDownloadModal}
				footer={[]}
				width={540}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Typography>{langdata && langdata.Totalnumberofrecords ? langdata.Totalnumberofrecords : 'Total number of records'}: {data.pagination.total}</Typography>
					</Col>
				</Row>

				{ data.pagination.total > 0 ? (
					<BatchDownloader total={data.pagination.total} url={url}/>
				) : (
					<>
						<Row gutter={16} style={{
							backgroundColor: '#d3d3d3',
							padding: '5px',
							marginTop: '10px'
						}}>
							<Col span={24}>
								<Typography>{langdata && langdata.Norecordsfound ? langdata.Norecordsfound : 'No records found'}</Typography>
							</Col>
						</Row>
						<br/>
					</>
          
				)}
			</Modal>
			{/* Fetch and Download Modal Modal Ends */}
			<Spin spinning={data.loading} fullscreen />
		</>
    
	);
} 